<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk5Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk4',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-国家能源'
    getBulk5Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '项目名称', value: res[0].title},
        {text: '招标航线', value: res[0].line},
        {text: '货种', value: res[0].goodsType},
        {text: '货量', value: res[0].weight},
        {text: '受载日期', value: res[0].loadDate},
        {text: '装卸条款', value: res[0].clause},
        {text: '报价时间', value: res[0].quotationDate},
        {text: '备注', value: res[0].remarks},
        {text: '说明条款', value: res[0].description},
        {text: '合同需方', value: res[0].contract},
        {text: '购方联系人', value: res[0].person},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
