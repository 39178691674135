import request from '@/utils/request'

export function getBulk1Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk1Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk2Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk2Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk3Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk3Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk4Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk4Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk5Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk5Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk6Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk6Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk7Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk7Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk8Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk8Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk9Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk9Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk10Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk10Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk11Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk11Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getBulk12Details(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBulk12Details.json",
    method: 'post',
    data: formFile,
  })
}

export function getNumbers(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getNumbers.json",
    method: 'post',
    data: formFile,
  })
}

export function getAuthor(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getAuthor.json",
    method: 'post',
    data: formFile,
  })
}

export function getAuthorHave(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getAuthorHave.json",
    method: 'post',
    data: formFile,
  })
}

export function addAuthority(formFile) {
  return request({
    url: "/api/ajax/bidPanel/addAuthority.json",
    method: 'post',
    data: formFile,
  })
}

export function deleteAuthority(formFile) {
  return request({
    url: "/api/ajax/bidPanel/deleteAuthority.json",
    method: 'post',
    data: formFile,
  })
}





